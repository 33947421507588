import React from "react"
import styled from "styled-components"
import { H1DarkGrey, B1DarkGrey, colors } from "../../../styles/helpers"

const SlideItem = styled.div`
  padding: 2rem 3.5rem;

  @media (min-width: 768px) {
    padding: 2rem;
  }

  h2 {
    ${H1DarkGrey};
    display: inline-block;
    position: relative;

    &::after {
      position: absolute;
      right: -1rem;
      bottom: 0;
      left: -1rem;
      height: 2.5rem;
      background-color: ${colors.colorPrimary};
      content: "";
      z-index: -1;
    }
  }

  p {
    ${B1DarkGrey};
  }
`

const SliderIntroItem = ({ slide }) => {
  return (
    <SlideItem>
      <h2>{slide.title}</h2>
      <div dangerouslySetInnerHTML={{ __html: slide.content }} />
    </SlideItem>
  )
}

export default SliderIntroItem
