import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import {
  standardWrapper,
  H1Lime,
  B1White,
  Btn1YellowTrans,
} from "../../../styles/helpers"
import BGImage from "gatsby-background-image"
import { createSlug } from "../../../utils/helperFunc"

const ConnectSection = styled.section`
  position: relative;
  margin-bottom: -10rem;

  .maskedWrapper {
    padding: 25vw 0 20vw;
    clip-path: polygon(0% 0%, 0% 100%, 100% 100%, 100% 25%);
  }

  .wrapper {
    ${standardWrapper};
    position: relative;
    z-index: 5;
  }

  .contentWrap {
    width: 100%;
    max-width: 35rem;
    margin: auto;
    text-align: center;

    h2 {
      ${H1Lime};
    }

    p {
      ${B1White};
    }

    a {
      ${Btn1YellowTrans};
    }
  }

  .connectBG {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;

    & > div {
      width: 100%;
      height: 100%;
    }
  }

  .connectBG-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.65);
    z-index: 2;
  }
`

const Connect = ({ connect }) => {
  const slug = createSlug(connect.acf._kon_con_button_link)
  return (
    <ConnectSection>
      <div className="maskedWrapper">
        <div className="wrapper">
          <div className="contentWrap">
            <div>
              <h2>{connect.acf._kon_con_title}</h2>
            </div>
            <div
              dangerouslySetInnerHTML={{ __html: connect.acf._kon_con_content }}
            />
            <div>
              <Link to={`/${slug}`}>{connect.acf._kon_con_button_text}</Link>
            </div>
          </div>
        </div>
        <div className="connectBG">
          <BGImage
            tag="div"
            fluid={connect.acf._kon_con_image.localFile.childImageSharp.fluid}
          />
        </div>
        <div className="connectBG-overlay" />
      </div>
    </ConnectSection>
  )
}

export default Connect
