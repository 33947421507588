import React, { useEffect } from "react"
import styled from "styled-components"
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import {
  standardWrapper,
  fontSizer,
  fonts,
  colors,
  B1DarkGrey,
  H1DarkGrey,
} from "../../../styles/helpers"

import IconBullseye from "../../Icons/IconBullseye"
import IconCog from "../../Icons/IconCog"
import IconLightbulb from "../../Icons/IconLightbulb"

gsap.registerPlugin(ScrollTrigger)

const HowToWorkSection = styled.section`
  .wrapper {
    ${standardWrapper};
  }

  .mainTitle {
    width: 100%;
    text-align: center;

    h2 {
      ${H1DarkGrey};

      span {
        display: inline-block;
      }
    }
  }
`

const Step = styled.div`
  width: calc(100% - 12rem);
  margin: 2rem 6rem;
  text-align: center;

  @media (min-width: 768px) {
    width: calc(33.33% - 12rem);
    margin: 6rem;
  }

  .icon {
    max-width: 16rem;
    margin: 1rem auto 2rem;
  }

  p:first-of-type {
    ${fontSizer(3, 5.5, 76.8, 150, 2.6)};
    margin: 0;
    color: ${colors.colorTertiary};
    font-family: ${fonts.fontSecondary};
    font-weight: bold;
  }

  p:last-of-type {
    ${B1DarkGrey};
  }
`

const HowToWork = ({ howToSteps }) => {
  useEffect(() => {
    const triggerElement = document.querySelector("#uowToWorkSec")
    const titleLetter = [
      ...triggerElement.querySelectorAll(".mainTitle h2 span"),
    ]
    const allSteps = [...document.querySelectorAll(".allSteps")]

    gsap.set(titleLetter, { autoAlpha: 0, y: -30 })
    gsap
      .timeline({
        scrollTrigger: {
          trigger: triggerElement,
          markers: false,
          start: "top 45%",
          toggleActions: "play none none none",
        },
      })
      .to(titleLetter, {
        autoAlpha: 1,
        y: 0,
        duration: 0.75,
        ease: "power4.out",
        stagger: {
          each: 0.1,
        },
      })

    gsap.set(allSteps, { y: 175, autoAlpha: 0 })

    gsap
      .timeline({
        scrollTrigger: {
          trigger: triggerElement,
          markers: false,
          start: "top 60%",
          toggleActions: "play none none none",
        },
      })
      .to(
        allSteps,
        { y: 0, autoAlpha: 1, duration: 0.5, stagger: 0.3 },
        "-=0.5"
      )
  }, [])

  const newTitle = "How To Work With Us".split("")

  return (
    <HowToWorkSection id="uowToWorkSec">
      <div className="wrapper">
        <div className="mainTitle">
          <h2>
            {newTitle.map((letter, index) => {
              return letter !== " " ? <span key={index}>{letter}</span> : " "
            })}
          </h2>
        </div>
        {howToSteps.acf._kon_htw_steps.map((step, index) => {
          const currentIcon =
            index === 0 ? (
              <IconLightbulb />
            ) : index === 1 ? (
              <IconCog />
            ) : (
              <IconBullseye />
            )
          return (
            <Step id={`step-${index + 1}`} className="allSteps" key={index}>
              <div className="icon">{currentIcon}</div>
              <div>
                <p>{index + 1}.</p>
                <p>{step.content}</p>
              </div>
            </Step>
          )
        })}
      </div>
    </HowToWorkSection>
  )
}

export default HowToWork
