import React, { useEffect } from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { createSlug } from "../../../utils/helperFunc"
import {
  standardWrapper,
  H1DarkGrey,
  B1DarkGrey,
  Btn1LimeGreen,
  colors,
} from "../../../styles/helpers"

gsap.registerPlugin(ScrollTrigger)

const WhyKonaSection = styled.section`
  @media (min-width: 768px) {
    margin-top: -12.5rem;
  }
  .wrapper {
    ${standardWrapper};
  }

  .contentWrap {
    max-width: 51.1rem;
    margin: 0 auto;
  }

  .title {
    text-align: center;
    h2 {
      ${H1DarkGrey};
    }
  }

  .content {
    margin-bottom: 2rem;
    p {
      ${B1DarkGrey};
    }
  }

  .listPoints {
    margin-bottom: 5rem;

    li {
      ${B1DarkGrey};
      position: relative;
      padding-left: 2rem;

      &::after {
        position: absolute;
        top: 50%;
        left: 0;
        width: 1rem;
        height: 1rem;
        transform: translateY(-40%);
        background-color: ${colors.colorPrimary};
        border-radius: 50%;
        overflow: hidden;
        content: "";
      }
    }
  }

  .btnLink {
    text-align: center;

    a {
      ${Btn1LimeGreen};
    }
  }
`

const WhyKona = ({ whyKona }) => {
  const slug = createSlug(whyKona.acf._kon_why_button_link)

  useEffect(() => {
    const triggerElement = document.querySelector("#whyKona")
    const whyTitle = triggerElement.querySelector(".title")
    const whyPara = triggerElement.querySelector(".content")
    const whyPoints = [...triggerElement.querySelectorAll(".listPoints li")]
    const whyBtn = triggerElement.querySelector(".btnLink")

    gsap.set([whyTitle, whyPara, whyPoints], {
      autoAlpha: 0,
      y: 100,
    })
    gsap.set(whyBtn, { autoAlpha: 0, scale: 0.25 })

    gsap
      .timeline({
        scrollTrigger: {
          trigger: triggerElement,
          markers: false,
          start: "top 45%",
          toggleActions: "play none none none",
        },
      })
      .to(whyTitle, { autoAlpha: 1, y: 0, duration: 0.5 })
      .to(whyPara, { autoAlpha: 1, y: 0, duration: 0.5 }, "-=0.25")
      .to(
        whyPoints,
        {
          autoAlpha: 1,
          y: 0,
          duration: 0.5,
          stagger: {
            each: 0.1,
          },
        },
        "-=0.25"
      )
      .to(whyBtn, {
        autoAlpha: 1,
        scale: 1,
        duration: 0.35,
        ease: "back.out(1.7)",
      })
  }, [])

  return (
    <WhyKonaSection id="whyKona">
      <div className="wrapper">
        <div className="contentWrap">
          <div className="title">
            <h2>{whyKona.acf._kon_why_title}</h2>
          </div>
          <div
            className="content"
            dangerouslySetInnerHTML={{ __html: whyKona.acf._kon_why_content }}
          />
          <ul className="listPoints">
            {whyKona.acf._kon_why_points.map((point, index) => (
              <li key={index}>{point.point}</li>
            ))}
          </ul>
          <div className="btnLink">
            <Link to={`${slug}`}>{whyKona.acf._kon_why_button_text}</Link>
          </div>
        </div>
      </div>
    </WhyKonaSection>
  )
}

export default WhyKona
