import React, { useEffect, useRef } from "react"
import Img from "gatsby-image"
import styled from "styled-components"
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { B1White, B1Lime, H3Lime } from "../../../styles/helpers"
gsap.registerPlugin(ScrollTrigger)

const DisplayItem = styled.div`
  ${props => (props.isActive ? "opacity: 1" : "opacity:0")};
  position: relative;
  display: flex;
  align-items: center;
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  ${"" /* transition: all 0.3s ease-in-out; */}
  z-index: 100;

  .displayItem__content {
    text-align: center;

    @media (min-width: 768px) {
      width: calc(50% - 4rem);
      margin-right: 4rem;
      padding: 0 2rem;
    }

    &--inner {
      width: 100%;
      max-width: 50rem;
      margin-left: auto;

      &--title {
        position: relative;
        transform: translateY(100px);
        opacity: 0;

        h2 {
          ${H3Lime};
          margin-top: 0;
        }
      }

      &--content {
        position: relative;
        transform: translateY(100px);
        opacity: 0;

        p {
          ${B1White};
        }
      }
    }

    .linkSec {
      width: 100%;
    }

    a {
      ${B1Lime};
      display: block;
      font-weight: bold;
    }
  }

  .displayItem__image {
    box-shadow: 0.3rem 0.3rem 0.6rem 0rem rgba(67, 67, 67, 0.5);

    @media (min-width: 768px) {
      width: 50%;
    }
  }
`

const InfoBlockItem = ({ block, isActiveItem }) => {
  const thisItem = useRef(null)
  const thisTitle = useRef(null)
  const thisContent = useRef(null)
  const thisImage = useRef(null)
  useEffect(() => {
    gsap
      .timeline()

      .fromTo(
        thisTitle.current,
        { autoAlpha: 0, y: 100 },
        { y: 0, autoAlpha: 1, duration: 0.75 }
      )
      .fromTo(
        thisContent.current,
        { autoAlpha: 0, y: 100 },
        { y: 0, autoAlpha: 1, duration: 0.75 },
        "-=0.5"
      )
      .fromTo(
        thisImage.current,
        { autoAlpha: 0, x: 100 },
        { x: 0, autoAlpha: 1, duration: 0.75 },
        "-=0.75"
      )
  }, [isActiveItem])

  return (
    <DisplayItem ref={thisItem} className="displayItem" isActive={isActiveItem}>
      <div className="displayItem__content">
        <div className="displayItem__content--inner">
          <div className="displayItem__content--inner--title" ref={thisTitle}>
            <h2>{block.title}</h2>
          </div>
          <div
            className="displayItem__content--inner--content"
            ref={thisContent}
            dangerouslySetInnerHTML={{ __html: block.content }}
          />
        </div>
      </div>
      <div ref={thisImage} className="displayItem__image">
        <Img
          fluid={block.image.localFile.childImageSharp.fluid}
          alt={block.image.alt_text}
        />
      </div>
    </DisplayItem>
  )
}

export default InfoBlockItem
