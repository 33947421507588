import React from "react"
import Img from "gatsby-image"
import styled from "styled-components"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { colors } from "../../../styles/helpers"

import SliderIntroItem from "./SliderIntroItem"

const settings = {
  fade: true,
  draggable: true,
  infinite: true,
  speed: 250,
  autoplay: true,
  autoplaySpeed: 15000,
  centerMode: true,
  centerPadding: "0px",
  arrows: false,
  dots: true,
}

const SliderIntroSection = styled.section`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content: flex-start;
  position: relative;
  padding: 1rem 0;

  @media (min-width: 768px) {
    padding: 7.5rem 0;
  }

  @media (min-width: 1025px) {
    padding: 7.5rem 0;
  }

  .imageWrap {
    position: relative;
    width: 100%;
    margin-bottom: -25rem;
    z-index: -1;

    @media (min-width: 768px) {
      width: 50%;
      max-width: 65.2rem;
      margin-bottom: 0;
    }
  }

  .slider {
    width: 100%;

    @media (min-width: 768px) {
      width: calc(50% - 2.5rem);
      max-width: 42.5rem;
      margin-top: 2.5rem;
      margin-left: 2.5rem;
    }

    @media (min-width: 1025px) {
      width: calc(50% - 7.5rem);
      max-width: 42.5rem;
      margin-top: 5rem;
      margin-left: 10rem;
    }

    @media (min-width: 1350px) {
      width: calc(50% - 7.5rem);
      max-width: 50rem;
      margin-top: 5rem;
      margin-left: 15rem;
    }

    @media (min-width: 1500px) {
      width: calc(50% - 7.5rem);
      max-width: 50rem;
      margin-top: 5rem;
      margin-right: auto;
      margin-left: auto;
    }

    .slick-dots {
      right: 0;
      bottom: 0;
      left: 0;
      width: auto;

      @media (min-width: 768px) {
        right: auto;
        bottom: 1rem;
        left: 1rem;
      }

      li {
        button {
          &::before {
            font-size: 1rem;
          }
        }
      }

      li.slick-active {
        button {
          &::before {
            color: ${colors.colorPrimary};
          }
        }
      }
    }
  }
`

const SliderIntro = ({ slider }) => {
  return (
    <SliderIntroSection>
      <div className="imageWrap">
        <Img
          fluid={
            slider.acf._kon_hote_slider_image.localFile.childImageSharp.fluid
          }
          alt={slider.acf._kon_hote_slider_image.alt_text}
        />
      </div>
      <Slider {...settings} className="slider">
        {slider.acf._kon_hote_slider.map((slide, index) => (
          <SliderIntroItem key={index} slide={slide} />
        ))}
      </Slider>
    </SliderIntroSection>
  )
}

export default SliderIntro
