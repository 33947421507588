import React from "react"
import styled from "styled-components"
import { colors, H2Lime } from "../../../styles/helpers"

const DisplayNavItem = styled.div`
  flex-grow: 1;
  padding: 0.5rem 2rem;
  text-align: center;

  @media (min-width: 768px) {
    padding: 2rem;
  }

  h2 {
    ${H2Lime};
    position: relative;
    z-index: 5;

    &::after {
      position: absolute;
      right: -1rem;
      bottom: -0.5rem;
      left: -1rem;
      height: 2rem;
      transition: all 0.3s ease;
      background-color: ${colors.greyBrown};
      content: "";
      z-index: -1;
    }

    &:hover {
      cursor: pointer;

      &::after {
        opacity: 1;
      }
    }
  }

  button {
    ${H2Lime};
    border: none;
    background-color: transparent;

    &:hover {
      cursor: pointer;
    }

    &::disabled {
      cursor: not-allowed;
    }

    &:focus {
      outline: none;
    }
  }

  h2 {
    &::after {
      ${props => (props.isActive ? "opacity: 1" : "opacity:0")};
    }
  }
`

const InfoBlockNavItem = ({
  index,
  handleContentChange,
  isActiveItem,
  block,
  changing,
}) => {
  console.log(isActiveItem)
  return (
    <DisplayNavItem isActive={isActiveItem} className="navItem">
      <h2>
        <button
          type="button"
          disabled={changing}
          onClick={() => {
            handleContentChange(index)
          }}
        >
          {block.title}
        </button>
      </h2>
    </DisplayNavItem>
  )
}

export default InfoBlockNavItem
