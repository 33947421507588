import React, { useEffect, useRef, useState } from "react"
import styled from "styled-components"
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

import { colors, medWrapper } from "../../../styles/helpers"
import { getMaxHeight } from "../../../utils/helperFunc"
import BlueLimeChameleon from "../../Chameleons/BlueLimeChameleon"
import InfoBlockItem from "./InfoBlockItem"
import InfoBlockNavItem from "./InfoBlockNavItem"

gsap.registerPlugin(ScrollTrigger)

const InfoBlocksSection = styled.section`
  position: relative;
  margin-bottom: 10rem;
  padding: 20rem 0 0;

  .wrapper {
    ${medWrapper};
  }

  .clippedBackground {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, ${colors.colorSecondary}, #03101d);
    clip-path: polygon(0 0, 0 100%, 100% 85%, 100% 15%);
    z-index: -1;
  }

  .chameleon {
    position: absolute;
    bottom: -2.5rem;
    left: 0;
    width: calc(44.7rem / 1.5);
    height: calc(21.8rem / 1.5);
    z-index: 5000;

    @media (min-width: 768px) {
      bottom: -10.5rem;
      width: 44.7rem;
      height: 21.8rem;
    }

    @media (min-width: 1025px) {
      bottom: -10.5rem;
    }
  }
`

const ContentNav = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-bottom: 0;
  z-index: 10;

  @media (min-width: 768px) {
    flex-wrap: nowrap;
    padding-bottom: 2rem;
  }
`

const ContentDisplay = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  min-height: ${props => props.minHeight + 125}px;
  z-index: 10;
`

const InfoBlocks = ({ infoBlocks }) => {
  const displayContainer = useRef(null)
  const chameleonBlue = useRef(null)
  const [minHeight, setMinHeight] = useState(0)
  const [itemActive, setItemActive] = useState(0)
  const [itemChanging, setitemChanging] = useState(false)

  useEffect(() => {
    const allElements = [
      ...displayContainer.current.querySelectorAll(".displayItem"),
    ]
    const newMinHeight = getMaxHeight(allElements)
    setMinHeight(newMinHeight)

    gsap.set(chameleonBlue.current, { y: 50 })

    gsap
      .timeline({
        scrollTrigger: {
          trigger: displayContainer.current,
          markers: false,
          start: "top 100%",
          end: "top -75%",
          scrub: true,
        },
      })
      .to(chameleonBlue.current, { y: -50, ease: "none" })
  }, [])

  const handleContentChange = item => {
    setItemActive(item)
    setitemChanging(true)
    setTimeout(() => {
      setitemChanging(false)
    }, 1000)
  }

  return (
    <InfoBlocksSection>
      <div className="wrapper">
        <ContentNav>
          {infoBlocks.acf._kon_info_information_blocks.map((block, index) => {
            const isActiveItem = index === itemActive ? true : false

            return (
              <InfoBlockNavItem
                key={index}
                index={index}
                handleContentChange={handleContentChange}
                isActiveItem={isActiveItem}
                block={block}
                changing={itemChanging}
                className="navItem"
              />
            )
          })}
        </ContentNav>
        <ContentDisplay
          className="displayContainer"
          ref={displayContainer}
          minHeight={minHeight}
        >
          {infoBlocks.acf._kon_info_information_blocks.map((block, index) => {
            const isActiveItem = index === itemActive ? true : false
            return (
              <InfoBlockItem
                className="displayItem"
                key={index}
                block={block}
                isActiveItem={isActiveItem}
              />
            )
          })}
        </ContentDisplay>
      </div>

      <div ref={chameleonBlue} className="chameleon">
        <BlueLimeChameleon />
      </div>
      <div className="clippedBackground" />
    </InfoBlocksSection>
  )
}

export default InfoBlocks
