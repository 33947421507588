import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import SEO from "../components/Seo"
// Components //
import SliderIntro from "../components/TemplateComponets/Home/SliderIntro"
import WhyKona from "../components/TemplateComponets/Home/WhyKona"
import InfoBlocks from "../components/TemplateComponets/Home/InfoBlocks"
import HowToWork from "../components/TemplateComponets/Home/HowToWork"
import Connect from "../components/TemplateComponets/Home/Connect"

const IndexPage = props => {
  const {
    seoInfo,
    slider,
    whyKona,
    infoBlocks,
    howToSteps,
    connect,
  } = props.data
  const location = props.location
  return (
    <Layout location={location}>
      <SEO
        title={seoInfo.acf._swb_theme_meta_title}
        description={seoInfo.acf._swb_theme_description}
        metaImg={seoInfo.acf._swb_theme_image.localFile.publicURL}
        location={location.pathname}
      />
      <SliderIntro slider={slider} />
      <WhyKona whyKona={whyKona} />
      <InfoBlocks infoBlocks={infoBlocks} />
      <HowToWork howToSteps={howToSteps} />
      <Connect connect={connect} />
    </Layout>
  )
}

export const homeQuery = graphql`
  {
    seoInfo: wordpressPage(slug: { eq: "home" }) {
      acf {
        _swb_theme_meta_title
        _swb_theme_description
        _swb_theme_image {
          localFile {
            publicURL
          }
        }
      }
    }

    slider: wordpressPage(slug: { eq: "home" }) {
      acf {
        _kon_hote_slider_image {
          alt_text
          localFile {
            childImageSharp {
              fluid(maxWidth: 1000) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        _kon_hote_slider {
          title
          content
        }
      }
    }

    whyKona: wordpressPage(slug: { eq: "home" }) {
      acf {
        _kon_why_title
        _kon_why_content
        _kon_why_points {
          point
        }
        _kon_why_button_text
        _kon_why_button_link
      }
    }

    infoBlocks: wordpressPage(slug: { eq: "home" }) {
      acf {
        _kon_info_information_blocks {
          title
          content
          image {
            alt_text
            localFile {
              childImageSharp {
                fluid(maxWidth: 1000) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          link
        }
      }
    }

    howToSteps: wordpressPage(slug: { eq: "home" }) {
      acf {
        _kon_htw_steps {
          content
        }
      }
    }

    connect: wordpressPage(slug: { eq: "home" }) {
      acf {
        _kon_con_title
        _kon_con_content
        _kon_con_button_text
        _kon_con_button_link
        _kon_con_image {
          alt_text
          localFile {
            childImageSharp {
              fluid(maxWidth: 1750) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`

export default IndexPage
